import deepmerge from "deepmerge";
import { type User } from "next-auth";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import { type SidePanelTypeEnum } from "~/app/(private)/_types/sidePanel.type";

export type TDashboardStore = TDashboardStoreActions & TDashboardStoreValues;

export type TDashboardStoreActions = {
  setIsImpersonating: (isImpersonating: boolean) => void;
  setIsSidebarCollapsed: (isSidebarCollapsed: boolean) => void;
  setSidePanel: (sidePanel: TDashboardStoreValues["sidePanel"]) => void;
  setUser: (user: TDashboardStoreValues["user"]) => void;
  toggleSidebarCollapsed: () => void;
  toggleSidePanel: (type: TDashboardStoreValues["sidePanel"]) => void;
};

export type TDashboardStoreValues = {
  isImpersonating: boolean;
  isSidebarCollapsed: boolean;
  sidePanel: null | SidePanelTypeEnum;
  user: null | User;
};

export const defaultInitState: TDashboardStoreValues = {
  isImpersonating: false,
  isSidebarCollapsed: false,
  sidePanel: null,
  user: null,
};

export const createDashboardStore = (
  initState?: Partial<TDashboardStoreValues>,
) => {
  return create<TDashboardStore>()(
    persist(
      immer(
        devtools(
          (set) => ({
            ...defaultInitState,
            ...initState,
            setIsImpersonating: (isImpersonating) =>
              set(
                (state) => {
                  state.isImpersonating = isImpersonating;
                },
                true,
                "setIsImpersonating",
              ),
            setIsSidebarCollapsed: (isSidebarCollapsed) =>
              set(
                (state) => {
                  state.isSidebarCollapsed = isSidebarCollapsed;
                },
                true,
                "setIsSidebarCollapsed",
              ),
            setSidePanel: (sidePanel) =>
              set(
                (state) => {
                  state.sidePanel = sidePanel;
                },
                true,
                "setSidePanel",
              ),
            setUser: (user) =>
              set(
                (state) => {
                  state.user = user;
                },
                true,
                "setUser",
              ),
            toggleSidebarCollapsed: () =>
              set(
                (state) => {
                  state.isSidebarCollapsed = !state.isSidebarCollapsed;
                },
                true,
                "toggleSidebarCollapsed",
              ),
            toggleSidePanel: (sidePanel) =>
              set(
                (state) => {
                  state.sidePanel = state.sidePanel === null ? sidePanel : null;
                },
                true,
                "setSidePanel",
              ),
          }),
          {
            enabled: process.env.NODE_ENV === "development",
            name: "DashboardStore",
          },
        ),
      ),
      {
        merge: (persistedState, currentState) =>
          deepmerge(currentState, persistedState as Partial<TDashboardStore>),
        name: "dashboardSession",
        partialize: (state) =>
          ({
            isSidebarCollapsed: state.isSidebarCollapsed,
          }) as Partial<TDashboardStore>,
        storage: createJSONStorage(() => sessionStorage),
      },
    ),
  );
};
